import type { ReactNode } from "react";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import type { AdminHostPoolWithType } from "./hostPoolsQuery.ts";
import { defaultVmsMutation } from "./hostPoolsQuery.ts";

export function ToggleDefaultDesignatedModal({ button, item }: { button: ReactNode; item: AdminHostPoolWithType }) {
    const updateMutation = useStandardMutation(defaultVmsMutation);

    async function onToggle() {
        await updateMutation.mutateAsync({
            location: item.location,
            uuid: item.uuid,
        });
    }

    return (
        <WModal button={button}>
            <WModalContent title="Set Pool Preselection" label="Set Default" modalAction={async () => await onToggle()}>
                <p>
                    This pool will be the default if user does not specifically choose another pool when creating a
                    Virtual Machine.
                </p>
            </WModalContent>
        </WModal>
    );
}
