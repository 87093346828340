import LR from "../../components/LeftRight.module.css";

import { LeftRight } from "../../components/LeftRight.tsx";
import { WSelect, type WSelectStyleProps, type WSelectValueProps } from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { StoragePoolWithType } from "./storagePoolsQuery.ts";
import { adminStoragePoolQueryAtom } from "./storagePoolsQuery.ts";

export interface StoragePoolSelectProps extends WSelectStyleProps, WSelectValueProps<StoragePoolWithType, string> {
    items: StoragePoolWithType[];
    location: string;
}

export function StoragePoolSelectCustom({ items, ...props }: StoragePoolSelectProps) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select pool"
            items={items}
            getKey={(item) => item.uuid}
            getTextValue={(item) => item.display_name}
            searchItems={(query, items) =>
                items.filter((item) => item.display_name.toLowerCase().includes(query.toLowerCase()))
            }
            renderSelectedValue={(item) => (
                <LeftRight
                    icon={<MaskIcon className="jp-hostpool-icon color-primary size-1rem" />}
                    title={item.display_name}
                    right={<>{item.percent_used.toFixed(1)}% used</>}
                />
            )}
            {...props}
        >
            {(item) => (
                <LeftRight
                    icon={<MaskIcon className="jp-hostpool-icon color-primary size-1rem" />}
                    title={
                        <div>
                            <div>{item.display_name}</div>
                            <div className="font-size-small color-primary">{item.percent_used.toFixed(1)}% used</div>
                        </div>
                    }
                    right={
                        <div className="font-size-small color-text">
                            <div>
                                <span className="color-muted pr-2">GB used:</span>
                                <span>{item.stored_gb}</span>
                            </div>
                            <div>
                                <span className="color-muted pr-2">GB total:</span>
                                <span>{item.total_gb}</span>
                            </div>
                        </div>
                    }
                />
            )}
        </WSelect>
    );
}

export interface StoragePoolSelectLoaderProps extends Omit<StoragePoolSelectProps, "items"> {}

function StoragePoolSelectLoader({ ...props }: StoragePoolSelectLoaderProps) {
    const data = useSuspenseQueryAtom(adminStoragePoolQueryAtom(props.location));
    return <StoragePoolSelectCustom items={[...data.values()]} defaultValueKey={props.valueKey} {...props} />;
}

export function StoragePoolSelect(props: StoragePoolSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <StoragePoolSelectCustom {...props} {...renderProps} />}>
            <StoragePoolSelectLoader {...props} />
        </WSelectWrapper>
    );
}
