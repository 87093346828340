import type { FormEvent, ReactNode } from "react";
import { useState } from "react";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { LocationSelect } from "../../modules/location/LocationSelect.tsx";
import { useLocationsForType } from "../../modules/location/query.ts";
import { StoragePoolSelect } from "../buckets/StoragePoolSelect.tsx";
import { AInput } from "../form/Fields.tsx";
import type { AdminHostPoolWithType } from "./hostPoolsQuery.ts";
import { hostPoolCreateMutation, hostPoolUpdateMutation } from "./hostPoolsQuery.ts";

export function AddHostPoolButton({ button }: { button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditHostPoolModalContent />
        </WModal>
    );
}

export function EditHostPoolButton({ item, button }: { item: AdminHostPoolWithType; button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditHostPoolModalContent item={item} />
        </WModal>
    );
}

export function AddEditHostPoolModalContent({ item }: { item?: AdminHostPoolWithType }) {
    const createMutation = useStandardMutation(hostPoolCreateMutation);
    const updateMutation = useStandardMutation(hostPoolUpdateMutation);
    const locations = useLocationsForType("virtual_machine");
    const [location, setLocation] = useState<string>(item?.location ?? locations.defaultLocation);
    const [name, setName] = useState<string>(item?.name ?? "");
    const [description, setDescription] = useState<string>(item?.description ?? "");
    const [cpu, setCpu] = useState<number>(item?.cpu_allocate_coefficient ?? 2.5);
    const [ram, setRam] = useState<number>(item?.mem_allocate_coefficient ?? 0.8);
    const [model, setModel] = useState<string>(item?.cpu_model ?? "");
    const [pool, setPool] = useState<string>(item?.storage_pool_uuid ?? "");
    const [position, setPosition] = useState<string | number>(item?.ui_position ?? 0);

    const isEditMode = !!item;

    async function onSubmit() {
        if (isEditMode) {
            await updateMutation.mutateAsync({
                body: {
                    name: name,
                    description: description,
                    cpu_model: model,
                    cpu_allocate_coefficient: cpu,
                    mem_allocate_coefficient: ram,
                    storage_pool_uuid: pool,
                    ui_position: Number(position),
                    uuid: item.uuid,
                },
                location: location,
                uuid: item.uuid,
            });
        } else {
            await createMutation.mutateAsync({
                body: {
                    name: name,
                    description: description,
                    cpu_model: model,
                    cpu_allocate_coefficient: cpu,
                    mem_allocate_coefficient: ram,
                    storage_pool_uuid: pool,
                },
                location: location,
            });
        }
    }

    function onChangeCpu(event: FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        setCpu(Number(value));
    }

    function onChangeRam(event: FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        setRam(Number(value));
    }

    return (
        <WModalContent
            isActionDisabled={!name || !cpu || !ram || !model}
            title={isEditMode ? "Edit Host Pool" : "Add Host Pool"}
            label={isEditMode ? "Edit" : "Add"}
            modalAction={async () => await onSubmit()}
        >
            <WTextField className="max-w-24rem" wide label="Location" onChange={setModel}>
                <LocationSelect
                    resourceType="virtual_machine"
                    valueKey={location}
                    onChange={(item) => setLocation(item.slug)}
                />
            </WTextField>

            <WTextField isRequired className="max-w-24rem" wide label="Pool name" onChange={setName}>
                <AInput value={name} />
            </WTextField>

            <WTextField wide label="Description" onChange={setDescription}>
                <AInput value={description} />
            </WTextField>

            <WTextField
                isRequired
                wide
                label={
                    <div className="inline-flex w-80%">
                        <span>CPU allocate coefficient</span>
                        <InfoTooltip
                            className="ml-1"
                            text="The total number of vCPUs available to Virtual Machines on a host is calculated by multiplying the number of CPU threads on that host by this coefficient."
                        />
                    </div>
                }
                onChange={undefined}
            >
                <AInput type="number" value={cpu} onChange={onChangeCpu} min="0.01" max="5" step="0.01" />
            </WTextField>

            <WTextField
                isRequired
                wide
                label={
                    <div className="inline-flex w-80%">
                        <span>Mem allocate coefficient</span>
                        <InfoTooltip
                            className="ml-1"
                            text="The total amount of vRAM available to Virtual Machines on a host is calculated by multiplying the amount of total memory on that host by this coefficient."
                        />
                    </div>
                }
                onChange={undefined}
            >
                <AInput type="number" value={ram} onChange={onChangeRam} min="0.01" max="0.95" step="0.01" />
            </WTextField>

            <WTextField isRequired className="max-w-24rem" wide label="CPU model" onChange={setModel}>
                <AInput value={model} />
            </WTextField>

            <WTextField isRequired wide label="Storage pool" onChange={undefined}>
                <StoragePoolSelect valueKey={pool} location={location} onChange={(item) => setPool(item.uuid)} />
            </WTextField>

            {isEditMode && (
                <WTextField
                    isRequired
                    className="max-w-24rem"
                    wide
                    label={
                        <div className="inline-flex w-80%">
                            <span>UI position</span>
                            <InfoTooltip
                                className="ml-1"
                                text="An integer that defines the order in which the pools are displayed for users. Smaller numbers come first."
                            />
                        </div>
                    }
                    onChange={setPosition}
                >
                    <AInput value={position} />
                </WTextField>
            )}
        </WModalContent>
    );
}
