import C from "./Radio.module.css";
import R from "./WFakeRadio.module.css";

import type { ReactNode } from "react";
import { Button } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";

export function WFakeRadio({
    children,
    isSelected,
    isDisabled,
    onClick,
}: {
    children?: ReactNode;
    isSelected?: boolean;
    isDisabled?: boolean;
    onClick?: (value: boolean) => void;
}) {
    const commonProps = {
        "data-selected": isSelected ? true : undefined,
        //"data-disabled": isDisabled ? true : undefined
    };

    function onChange() {
        if (!isSelected && onClick) {
            onClick(!isSelected);
        }
    }

    return (
        <Button isDisabled={isDisabled} className={cn(C.Radio, R.Radio)} {...commonProps} onPress={onChange}>
            {children}
        </Button>
    );
}
