import { notNull } from "@warrenio/utils/notNull";
import type { ReactNode } from "react";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import type { AdminHostPoolWithType } from "./hostPoolsQuery.ts";
import { hostPoolUpdateMutation } from "./hostPoolsQuery.ts";

export function ToggleVisibleModal({ button, item }: { button: ReactNode; item: AdminHostPoolWithType }) {
    const updateMutation = useStandardMutation(hostPoolUpdateMutation);
    const { is_visible } = item;

    async function onToggle() {
        await updateMutation.mutateAsync({
            body: {
                name: item.name,
                description: item.description,
                cpu_model: item.cpu_model,
                cpu_allocate_coefficient: item.cpu_allocate_coefficient,
                mem_allocate_coefficient: item.mem_allocate_coefficient,
                storage_pool_uuid: item.storage_pool_uuid,
                ui_position: notNull(item.ui_position),
                uuid: item.uuid,
                is_visible: !is_visible,
            },
            location: item.location,
            uuid: item.uuid,
        });
    }

    return (
        <WModal button={button}>
            <WModalContent
                title="Toggle Host Pool visibility"
                label={is_visible ? "Hide Pool" : "Show Pool"}
                modalAction={async () => await onToggle()}
            >
                {is_visible ? (
                    <p>This will make this host pool not visible for end-users.</p>
                ) : (
                    <p>This will make this host pool visible for end-users.</p>
                )}
            </WModalContent>
        </WModal>
    );
}
