import type { ReactNode } from "react";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import type { AdminHostPoolWithType } from "./hostPoolsQuery.ts";
import { defaultHostsMutation } from "./hostPoolsQuery.ts";

export function ToggleHvAutoAssignedModal({ button, item }: { button: ReactNode; item: AdminHostPoolWithType }) {
    const updateMutation = useStandardMutation(defaultHostsMutation);

    async function onToggle() {
        await updateMutation.mutateAsync({
            location: item.location,
            uuid: item.uuid,
        });
    }

    return (
        <WModal button={button}>
            <WModalContent
                title="Set Default Pool for Hosts"
                label="Set Default"
                modalAction={async () => await onToggle()}
            >
                <p>New hosts that are added to the cluster will be automatically assigned to this pool.</p>
            </WModalContent>
        </WModal>
    );
}
